






import { stateModule } from '@/store'
import { RawLocation, Route } from 'vue-router'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { ProcessResource } from '@/models/process/ProcessResource'
import { ProjectResource } from '@/models/projects/ProjectResource'
import { ProposalResource } from '@/models/proposals/ProposalResource'

import ProposalDetail from '@/components/proposal/detail/Index.vue'

@Component({
  components: {
    ProposalDetail
  }
})
export default class FlexibleDocumentProposalDetail extends Vue {

  @Prop()
  private project!: ProjectResource

  private process: ProcessResource | null = null
  private proposal: ProposalResource | null = null

  // Route hooks
  private async created(): Promise<void> {
    if (this.$route.params.process_id) {

      const process_id = parseInt(this.$route.params.process_id, 10)
      const proposal_id = parseInt(this.$route.params.proposal_id, 10)
      stateModule.setLoading(true)

      try {
        const process = await this.project.getProcess(process_id)
        this.process = process.data
        const proposal = await this.process.getProposal(proposal_id)
        this.proposal = proposal.data

      } finally {
        stateModule.setLoading(false)
      }
    }
  }

  private async beforeRouteUpdate(to: Route, from: Route, next: any): Promise<void> {
    try {
      stateModule.setLoading(true)
      if (`${to.params.process_id}` !== `${from.params.process_id}`) {
        const { data } = await this.project.getProcess(parseInt(to.params.process_id, 10))
        this.process = data
      }
      if (`${to.params.proposal_id}` !== `${from.params.proposal_id}` && this.process) {
        const proposal = await this.process.getProposal(parseInt(to.params.proposal_id, 10))
        this.proposal = proposal.data
      }
      next()
    } catch {
      next(false)
    } finally {
      stateModule.setLoading(false)
    }
  }


  private get backRoute(): RawLocation {
    const params = { ...this.$route.params }
    delete params.proposal_id
    delete params.process_id
    return { name: 'projects-flexible-document', params }
  }

}
